import React from 'react';
import Config from '../config.js'
import './style.css';

const Stone = (props) => {
  return (
    <div 
      onClick = {props.onClick}
      className={'stone ' + props.value}
      style={{
        top: props.center.top - Config.STONE_DIAMETER / 2,
        left: props.center.left - Config.STONE_DIAMETER / 2,
        height: Config.STONE_DIAMETER,
        width: Config.STONE_DIAMETER
      }}
    >
    </div>  
  )
}

export default Stone;