import React from 'react'
import Config from '../config.js'

const handicapPointDiameter = 6;
const backgroundColor = '#555';
const lineWidth = '2px';

// note: skiiping letter I as convention in Go
const HORIZONTAL_INDEXES = [
  'A', 'B', 'C', 'D', 'E', 'F', 
  'G', 'H', 'J', 'K', 'L', 'M', 
  'N', 'O', 'P', 'Q', 'R', 'S', 'T'
];

const indexStyle = {
  position: 'absolute',
  fontWeight: 'bold',
  lineHeight: 1,
  fontSize: '14px',
  userSelect: 'none'
}  

export function renderVerticalIndexes(boardSize, isLeft) {
  const style = {...indexStyle}
  if (isLeft) {
    style.left = Config.INDEX_LABEL_MARGIN
  } else {
    style.right = Config.INDEX_LABEL_MARGIN
  }
  return Array(boardSize).fill(0).map((e, index) => {
    style.bottom = (Config.BOARD_MARGIN - 7) + (index * Config.CELL_SIZE) + 'px'
    console.log(style)

    return (
      <div 
        key={index} 
        style={{...style}}
      >
        { index + 1 }
      </div>
    )  
  })
}

export function renderHorizontalIndexes(boardSize, isTop) {
  const style = {...indexStyle}
  if (isTop) {
    style.top = Config.INDEX_LABEL_MARGIN
  } else {
    style.bottom = Config.INDEX_LABEL_MARGIN
  }  

  return HORIZONTAL_INDEXES.slice(0, boardSize).map((letter, index) => {
    
    // 4 - to center letter
    style.left = `${Config.BOARD_MARGIN - 4 + index * Config.CELL_SIZE}px`;

    return (
      <div 
        key={index}
        style={{...style}}
      >
        {letter}
      </div>
    )  
  })
}