
import React, { useMemo, useState } from 'react';
import './App.css';
import Board from './Board/Board.js';
var _ = require('lodash');

const BOARD_SIZE = 9;

const getEmptyBoardPosition = () => {
  const position = []
  for (let row = 0; row < BOARD_SIZE; row++) {  
    const row = [];  
    for (let column = 0; column < BOARD_SIZE; column++) {
      row.push(0)
    }
    position.push(row)
  }
  return position
}

const App = (props) => {
  const [boardPosition, setBoardPosition] = useState(getEmptyBoardPosition())
  const [mode, setMode] = useState('')

  const generateRandomPosition = () => {
    setBoardPosition(getRandomPosition());
  }

  const clearBoard = () => {
    setBoardPosition(getEmptyBoardPosition())
  }
  
  const getRandomPosition = () => {
    const position = [];

    for (let row = 0; row < BOARD_SIZE; row++) {  
      const row = [];  
      for (let column = 0; column < BOARD_SIZE; column++) {
        row.push(Math.random() > 0.75 ? -1 : Math.random() > 0.5 ? 1 : 0)
      }
      position.push(row)
    }
    return position
  }

  const handleStoneClick = (row, column) => {
    if (mode !== 'tools-eraser' 
      && boardPosition[row][column] !== 0) {
      return
    }

    const position = _.cloneDeep(boardPosition)
    
    switch (mode) {
      case 'tools-black':
        position[row][column] = -1
        break
      case 'tools-white':
        position[row][column] = 1
        break
      case 'tools-eraser':  
        position[row][column] = 0
        break
      default:
    }
    
    setBoardPosition(position)
  }

  const handleNewGameClick = () => {
    clearBoard()
  }
  //const blackToolsClass = 'tools-stone black' + (this.state.mode === 'tools-black' ? ' active' : '');
  //const whiteToolsClass = 'tools-stone white' + (this.state.mode === 'tools-white' ? ' active' : '');

  return (
    <div className="App">
      <Board 
        size={BOARD_SIZE} 
        mode={'tools-black'}
        position={boardPosition} 
        onClick={(row, column) => handleStoneClick(row, column)}
      />
        <div 
          style={{
            marginTop:10, 
            marginBottom:20
          }}
        >
          <button  
            onClick={handleNewGameClick}
            style={{'marginRight':15}}
          >
            new game
          </button>

          <button  
            onClick={generateRandomPosition}
            style={{'marginRight':15}}
          >
            random position
          </button>
          
          <button 
            onClick={clearBoard}
          >
            clear board
          </button>
        </div>

        <h3>
          Tools
        </h3>

        <div className="tools">
          <div 
            className={'tool-wrapper' + (mode === 'tools-black' ? ' active' : '')}
          >
            <div 
              className="tool-stone black"
              onClick={() => setMode('tools-black')} 
            >
            </div>  
          </div>
          
          <div 
            className={`tool-wrapper ${mode === 'tools-white' ? ' active' : ''}`}
          >
            <div 
              onClick={() => setMode('tools-white')} 
              className="tool-stone white"
            
            >
            </div>
          </div>
                    
          <div 
            className={`tool-wrapper tool-eraser ${mode === 'tools-eraser' ? ' active' : ''}`}
          >
            <div 
              onClick={() => setMode('tools-eraser')}
            >
              erase
            </div>
          </div>
        </div>
      </div>
  )
}

export default App