
import React from 'react';
import Config from '../config.js'

import {renderHorizontalIndexes, renderVerticalIndexes} from '../utils/index.js'
import './style.css';
import Stone from '../Stone/Stone.js'

const Board = (props) => {
  const SIZE_ARRAY = Array(props.size).fill(1)
  const BOARD_LENGTH = Config.CELL_SIZE * (props.size - 1)
  
  const renderLines = (direction) => {
    let left = 0;
    let top = 0;

    return SIZE_ARRAY.map((number, index) => {
      let style = {
        left: left, 
        top:top
      }

      if (direction === 'vertical') {
        style.height = BOARD_LENGTH
        left = left + Config.CELL_SIZE
      } else {
        style.width = BOARD_LENGTH    
        top = top + Config.CELL_SIZE;
      }

      return (
        <div 
          key={index}
          style={style}
          className={'boardLine ' + direction}
        >
        </div>
      )
    })
  }

  const renderPosition = () => {
    return props.position.map((rowValues, rowIndex) => {
      return rowValues.map((value, columnIndex) => {
        return (
          <Stone 
            onClick = {() => props.onClick(rowIndex, columnIndex)}
            value={value === -1 ? 'black' : value === 1 ? 'white' : 'empty'}
            stoneDiameter={Config.CELL_SIZE - 2}
            center={{left: Config.CELL_SIZE * columnIndex, top: Config.CELL_SIZE * rowIndex}}
            key={rowIndex + '.' + columnIndex}>
          </Stone>
        )      
      })
    }) 
  }
  
  const leftIndexes = renderVerticalIndexes(props.size, true);
  const rightIndexes = renderVerticalIndexes(props.size, false);
  const topIndexes = renderHorizontalIndexes(props.size, true);
  const bottomIndexes = renderHorizontalIndexes(props.size, false);

  return (
    <div 
      className="board-wrapper"
      style={{
        height: BOARD_LENGTH + Config.BOARD_PADDING * 2, 
        width: BOARD_LENGTH + Config.BOARD_PADDING * 2,
        border: '1px solid #999', 
        borderRadius: '5px',
      }}
    >
      {leftIndexes}
      {rightIndexes}
      {topIndexes}
      {bottomIndexes}

      <div 
        className="board"
        style={{
          position: 'relative',
          height: BOARD_LENGTH, 
          width: BOARD_LENGTH, 
          top: Config.BOARD_PADDING, 
          left: Config.BOARD_PADDING,
        }}
      >
        {renderLines('vertical')}
        {renderLines('horizontal')}
        {renderPosition()}
      </div>  
    </div>
  )
}

export default Board;