const CELL_SIZE = 40
const BOARD_PADDING = CELL_SIZE
const BOARD_MARGIN = CELL_SIZE
const INDEX_LABEL_MARGIN = '7px';
const STONE_DIAMETER = CELL_SIZE - 4

const Config = {
  BOARD_MARGIN,
  BOARD_PADDING,
  CELL_SIZE,
  INDEX_LABEL_MARGIN,
  STONE_DIAMETER
}

export default Config